(() => {
	const siteName = 'default-package_2023_vanilla';
	const htmlHasClass = (_class) => { return document.documentElement.classList.contains(_class); };
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: window.innerWidth,
				height: window.innerHeight
			},
			header: {
				desktop: 100,
				sp: 84
			}
		},
		localDecision() {
			const regExp = new RegExp(`(test\\.${siteName}\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading() { // ローカル環境ならincludeをAjaxで読み込む
			const _this = this;
			const key = 'inc_';
			let ajaxArray = [];
			let ajaxArrayList = [];
			let includeClass = document.querySelectorAll(`[class*="${key}"]`);

			includeClass.forEach(ele => {
				let path = ele.innerHTML.split(' ')[1];
				ajaxArray.push(path);
			});

			if (ajaxArray.length) {
				ajaxArray.forEach(ele => {
					let xhr = new XMLHttpRequest();
					let promise = new Promise((resolve, reject) => {
						xhr.onreadystatechange = function () {
							if (xhr.readyState === 4) {
								if (xhr.status === 200) {
									resolve(xhr.responseText);
								} else {
									reject(xhr.statusText);
								}
							}
						};
					});
					xhr.open('GET', ele + '.html', true);
					xhr.send();
					ajaxArrayList.push(promise);
				});

				Promise.all(ajaxArrayList).then(results => {
					let regExp = new RegExp(key);

					results.forEach(ele => {
						let classList = ele.split(/ |\"/g);
						let position;

						classList.forEach(ele2 => {
							if (ele2.match(regExp)) { position = ele2; }
						});
						document.querySelectorAll('.' + position).forEach(ele2 => {
							ele2.outerHTML = ele;
						});
						console.log('Succeeded to read the include file!:', position);
					});

					_this.loadDelayScript();
				}).catch((error) => {
					console.error('Failed to read the include file:', error);
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
			// ローカル環境のみadobeフォントのjs読み込みを行う。CMS上ではルート設定で設定
			(function(d) {
				var config = {
				kitId: 'uae1hrj',
				scriptTimeout: 3000,
				async: true
				},
				h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
			})(document);
		},
		getQuery() {
			let vars = [];
			let hash = null;
			let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			hashes.forEach(ele => {
				hash = ele.split('=');
				hash[1] = (hash[1].indexOf('#') !== -1) ? hash[1].split('#')[0] : hash[1];
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			});
			return vars;
		},
		nextToggle() {
			const switchElements = document.querySelectorAll('.js-toggle');

			switchElements.forEach(switchElement => {
				switchElement.addEventListener('click', e => {
					e.currentTarget.classList.toggle(a);

					let toggleBlock = e.currentTarget.nextElementSibling;
					toggleBlock.classList.toggle(a);
					if (toggleBlock.style.height) {
						toggleBlock.style.height = null;
						toggleBlock.style.visibility = 'hidden';
					} else {
						toggleBlock.style.height = toggleBlock.scrollHeight + 'px';
						toggleBlock.style.visibility = 'visible';
					}
				});
			});
		},
		tabSwitch() {
			let $tab = document.querySelectorAll('.js-tab');
			let $content = document.querySelectorAll('.js-tab-content');
			let tabSelect = document.querySelectorAll('.js-tab-select')

			if ($tab.length) {
				// ハッシュタグで絞り込み
				if (location.hash) {
					let hash = location.hash.split('#')[1];
					$tab.forEach(ele => {
						ele.classList.remove(a);
						if (ele.getAttribute('data-target').match(hash)) {
							ele.classList.remove(a);
						}
					});
					$content.forEach(ele => {
						ele.classList.remove(a);
						ele.classList.remove(v);
						if (ele.getAttribute('data-target').match(hash)) {
							$(ele).addClass(a);
							setTimeout(() => { ele.classList.add(v); }, 200);
						}
					});
				}

				$tab.forEach(ele => {
					ele.addEventListener('click', () => {
						
						let tabContentArr = [];
						ele.parentNode.querySelectorAll(".js-tab").forEach(tabBtn => {tabContentArr.push(tabBtn.dataset.target)})
						
						$tab.forEach(ele2 => {
							if(tabContentArr.includes(ele2.getAttribute('data-target'))) {
								ele2.classList.remove(a);
							}
						})
						ele.classList.add(a);
						$content.forEach(ele => {
							if(tabContentArr.includes(ele.getAttribute('data-target'))) {
								ele.classList.remove(a);
								ele.classList.remove(v);
							}
						});

						let $target;
						let target = ele.getAttribute('data-target');
						if (target === 'all') {
							$target = $content;
						} else {
							$target = document.querySelectorAll('[data-target="' + target + '"]');
						}
						$target.forEach(ele => {
							ele.classList.add(a);
							setTimeout(() => { ele.classList.add(v); }, 200);
						});

						if(tabSelect) {
							let option = document.querySelector('option[value="' + target + '"]')
							if(!option) return;
							let select = option.parentNode.querySelectorAll("option");

							select.forEach(ele => {
								ele.selected = false;
							})
							option.selected = true;
						}
					});
				});

				if(tabSelect) {
					tabSelect.forEach(ele => {
						ele.addEventListener('change', function() {
							let tabContentArr = [];
							ele.querySelectorAll("option").forEach(tabBtn => {tabContentArr.push(tabBtn.value)})

							$tab.forEach(ele2 => { 
								if(tabContentArr.includes(ele2.getAttribute('data-target'))) {
									ele2.classList.remove(a);
								}
							})

							$content.forEach(ele => {
								console.log(ele)
								if(tabContentArr.includes(ele.getAttribute('data-target'))) {
									ele.classList.remove(a);
									ele.classList.remove(v);
								}
							});

							let $target;
							let target = ele.value;
							if (target === 'all') {
								$target = $content;
							} else {
								$target = document.querySelectorAll('[data-target="' + target + '"]');
							}
							$target.forEach(ele => {
								ele.classList.add(a);
								setTimeout(() => { ele.classList.add(v); }, 200);
							});
						});
					})
				}
			}
		},
		easeScroll() {
			const scrollToObject = (_self) => {
				let hash = _self.currentTarget.href.split('#')[1];
				let pos = hash === 'pagetop' ? 0 : document.getElementById(hash).getBoundingClientRect().top;
				let offset = hash === 'pagetop' ? 0 : window.scrollY;
				pos += offset;

				if (hash !== 'pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos = pos - FUNCTIONS.va.header.sp;
					} else {
						pos = pos - FUNCTIONS.va.header.desktop;
					}
				}

            	if (window.innerWidth <= 1024) {
			        pos -= 10;
			    } else {
			        pos -= 20;
			    }

				window.scrollTo({ top: pos, behavior: 'smooth' });
			}
			// フォーカス用関数
			const scrollFocus = (_hash) => {
				let hash = _hash;
				if (hash !== 'pagetop') {
					const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
					const focusableElements = Array.from(
						document.getElementById(hash).querySelectorAll(focusableElementsSelector)
					);
					if(focusableElements.length) {
						focusableElements[0].focus({ preventScroll: true });
					}
				}
			};
			// スクロール完了後に実行する関数
			let scrollResolveTimeout;
			const waitForScrollComplete = (_hash) => {
			  return new Promise((resolve) => {
				const scrollTimeout = setTimeout(() => {
				  resolve(true);
				}, 100);
				const scrollTimeoutEvent = addEventListener('scroll', function fn (e) {
					clearTimeout(scrollTimeout);
					clearTimeout(scrollResolveTimeout);
					scrollResolveTimeout = setTimeout(function () {
						scrollFocus(_hash);
						removeEventListener('scroll', fn);
						resolve(true);
					}, 100);
				});
			  });
			};
			document.querySelectorAll('a[rel="scroll"]').forEach((ele) => {
				ele.addEventListener('click', async (e) => {
					e.preventDefault();
					if (/#/.test(e.currentTarget.href)) {
						scrollToObject(e);
						let hash = e.currentTarget.href.split('#')[1];
						await waitForScrollComplete(hash);
						return false;
					}
				});
			});
		},
		easeScrollHash() {
			const hash = location.hash;
			if (hash) {
				// フォーカス用関数
				const scrollFocus = (_hash) => {
					let hash = _hash;
					if (hash !== 'pagetop') {
						const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
						const focusableElements = Array.from(
							document.getElementById(hash).querySelectorAll(focusableElementsSelector)
						);
						if(focusableElements.length) {
							focusableElements[0].focus({ preventScroll: true });
						}
					}
				}
				// スクロール完了後に実行する関数
				let scrollResolveTimeout;
				const waitForScrollComplete = (_hash) => {
					return new Promise((resolve) => {
						const scrollTimeout = setTimeout(() => {
						resolve(true);
						}, 100);
						const scrollTimeoutEvent = addEventListener('scroll', function fn (e) {
							clearTimeout(scrollTimeout);
							clearTimeout(scrollResolveTimeout);
							scrollResolveTimeout = setTimeout(function () {
								scrollFocus(_hash);
								removeEventListener('scroll', fn);
								resolve(true);
							}, 100);
						});
					});
				}
				window.addEventListener('load', async () => {
					document.documentElement.style.display = 'none';
					document.body.style.display = 'none';

					setTimeout(() => {
						document.documentElement.style.display = 'block';
						document.body.style.display = 'block';

						let pos = document.getElementById(hash.split('#')[1]).getBoundingClientRect().top;

						if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
							pos -= FUNCTIONS.va.header.sp;
						} else {
							pos -= FUNCTIONS.va.header.desktop;
						}
						window.scrollTo({ top: pos, behavior: 'smooth' });
					}, 100);
					await waitForScrollComplete(hash);
				});
			}
		},
		pageTop() {
			let footer = document.querySelector('.footer');
			let $pagetop = document.querySelector('.footer-pagetop');

			if ($pagetop) {
				window.addEventListener('scroll', (e) => {
					if (window.scrollY > '100') {
						$pagetop.classList.add(v);
					} else {
						$pagetop.classList.remove(v);
					}
				});
			}
		},
		headerMenu() {
			let html = document.querySelector('html');

			window.addEventListener('scroll', handleScroll);
			window.addEventListener('resize', handleScroll);

			let lastScrollTop = 0;
			let scrollBackAmount = 0;
			let threshold = 0; // 適切な閾値を設定してください
			let $html = document.documentElement;

			function handleScroll() {
			    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			    let isScrollBack = (lastScrollTop - scrollTop) >= 0;
			    
			    if (isScrollBack) {
			        scrollBackAmount += lastScrollTop - scrollTop;
			    } else {
			        scrollBackAmount = 0;
			    }
			    
			    if (scrollTop >= threshold) {
			        $html.classList.add('is-scrolled');
			    } else {
			        $html.classList.remove('is-scrolled');
			    }
			    
			    if (scrollBackAmount >= 1) {
			        $html.classList.add('is-scroll-back');
			    } else {
			        $html.classList.remove('is-scroll-back');
			    }
			    
			    lastScrollTop = scrollTop;
			}

			if(document.querySelector('.l-header__menu-toggler')) {
				document.querySelector('.l-header__menu-toggler').addEventListener('click', function () {
					html.classList.toggle('is-menu-open');
				});
			}
			
			//言語切替
			let changeLangButton = document.querySelector('.js-changeLang');
		    let isActive = false; // クラスの状態を管理するフラグ
		    
		    // クリックイベントのリスナーを追加
		    if(changeLangButton) {
				changeLangButton.addEventListener('click', function() {
					// changeLangクラスの要素にis-activeクラスを追加/削除
					let changeLangElements = document.querySelectorAll('.changeLang');
					changeLangElements.forEach(function(element) {
						if (!isActive) {
							element.classList.add('is-active');
						} else {
							element.classList.remove('is-active');
						}
					});
					// 状態を反転
					isActive = !isActive;
				});
			}

			// 言語切替　URL書き換え
			let currentPath = window.location.pathname;

			// PC
			let pcChangeLangElements = document.querySelectorAll(".changeLang");
			pcChangeLangElements.forEach(function(changeLangElement) {
			    let langLinks = changeLangElement.querySelectorAll(".lang");
			    langLinks.forEach(function(langLink) {
			        let href = langLink.getAttribute("href");
			        // href を現在のパスに書き換える
			        let newPath = currentPath;
			        // href が "/" であればパスを "/" にする
			        if (href === "/") {
			            newPath = currentPath.replace(/^\/[^\/]+/, '');
			        }
			        // href が "/eng/" または "/cn/" であれば、現在のパスの先頭に言語コードを追加する
			        else if (href.startsWith("/eng/")) {
			            newPath = '/eng' + currentPath.replace(/^\/(eng|cn)/, '');
			        }
			        else if (href.startsWith("/cn/")) {
			            newPath = '/cn' + currentPath.replace(/^\/(eng|cn)/, '');
			        }
			        // 書き換えたパスを設定する
			        langLink.setAttribute("href", newPath);
			    });
			});

			// SP
			let spChangeLangElements = document.querySelectorAll("._spLang-in");
			spChangeLangElements.forEach(function(changeLangElement) {
			    let langLinks = changeLangElement.querySelectorAll(".lang");
			    langLinks.forEach(function(langLink) {
			        let href = langLink.getAttribute("href");
			        // href を現在のパスに書き換える
			        let newPath = currentPath;
			        // href が "/" であればパスを "/" にする
			        if (href === "/") {
			            newPath = currentPath.replace(/^\/[^\/]+/, '');
			        }
			        // href が "/eng/" または "/cn/" であれば、現在のパスの先頭に言語コードを追加する
			        else if (href.startsWith("/eng/")) {
			            newPath = '/eng' + currentPath.replace(/^\/(eng|cn)/, '');
			        }
			        else if (href.startsWith("/cn/")) {
			            newPath = '/cn' + currentPath.replace(/^\/(eng|cn)/, '');
			        }
			        // 書き換えたパスを設定する
			        langLink.setAttribute("href", newPath);
			    });
			});
		},
		headerMegaMenu() {
			// メガメニュー

			let slideUp = (target, duration=500) => {
			    target.style.transitionProperty = 'height, margin, padding';
			    target.style.transitionDuration = duration + 'ms';
			    target.style.boxSizing = 'border-box';
			    target.style.height = target.offsetHeight + 'px';
			    target.offsetHeight;
			    target.style.overflow = 'hidden';
			    target.style.height = 0;
			    target.style.paddingTop = 0;
			    target.style.paddingBottom = 0;
			    target.style.marginTop = 0;
			    target.style.marginBottom = 0;
			    window.setTimeout( () => {
			      target.style.display = 'none';
			      target.style.removeProperty('height');
			      target.style.removeProperty('padding-top');
			      target.style.removeProperty('padding-bottom');
			      target.style.removeProperty('margin-top');
			      target.style.removeProperty('margin-bottom');
			      target.style.removeProperty('overflow');
			      target.style.removeProperty('transition-duration');
			      target.style.removeProperty('transition-property');
			    }, duration);
			}

			let slideDown = (target, duration=500) => {
			    target.style.removeProperty('display');
			    let display = window.getComputedStyle(target).display;
			    if (display === 'none')
			      display = 'block';
			    target.style.display = display;
			    let height = target.offsetHeight;
			    target.style.overflow = 'hidden';
			    target.style.height = 0;
			    target.style.paddingTop = 0;
			    target.style.paddingBottom = 0;
			    target.style.marginTop = 0;
			    target.style.marginBottom = 0;
			    target.offsetHeight;
			    target.style.boxSizing = 'border-box';
			    target.style.transitionProperty = "height, margin, padding";
			    target.style.transitionDuration = duration + 'ms';
			    target.style.height = height + 'px';
			    target.style.removeProperty('padding-top');
			    target.style.removeProperty('padding-bottom');
			    target.style.removeProperty('margin-top');
			    target.style.removeProperty('margin-bottom');
			    window.setTimeout( () => {
			      target.style.removeProperty('height');
			      target.style.removeProperty('overflow');
			      target.style.removeProperty('transition-duration');
			      target.style.removeProperty('transition-property');
			    }, duration);
			}

			document.body.insertAdjacentHTML('beforeend', '<div class="l-megamenu-overlay"></div>');

			let items = document.querySelectorAll('.l-header__gnav__item');
			let headerGnav = document.querySelector('.l-header__gnav');
			let megamenuOverlay = document.querySelector('.l-megamenu-overlay');
			let breakpoint = 1024;

			items.forEach(function(item) {
			    item.addEventListener('mouseenter', function() {
				    let currentMegamenu = item.querySelector('.l-header__gnav__megamenu');
				    if (currentMegamenu && document.body.clientWidth > breakpoint) {
				        currentMegamenu.style.zIndex = 2;
				        if (currentMegamenu.style.display === 'none' || !currentMegamenu.style.display) {
				            slideDown(currentMegamenu, 600);
				        }
				        megamenuOverlay.classList.add('is-active');
				    }
				});

			    item.addEventListener('mouseleave', function() {
				    let currentMegamenu = item.querySelector('.l-header__gnav__megamenu');
				    if (currentMegamenu) {
			            if (!item.matches(':hover')) {
			                currentMegamenu.style.zIndex = 1;
			                slideUp(currentMegamenu, 150);
			                megamenuOverlay.classList.remove('is-active');
			            }
				    }
				});
			});
		},
		setImgAttrWidthHeight() {
			const getImg = (_src) => {
				return new Promise((resolve, reject) => {
					const image = new Image();
					image.src = _src;
					image.onload = () => { resolve(image); }
					image.onerror = (error) => { reject(error); }
				});
			};

			const imgs = document.getElementsByTagName('img');

			for (const img of imgs) {
				const src = img.getAttribute('src');
				getImg(src).then((res) => {
					if (!img.hasAttribute('width')) {
						img.setAttribute('width', res.width);
					}
					if (!img.hasAttribute('height')) {
						img.setAttribute('height', res.height);
					}
				})
					.catch((error) => { console.log(error); });
			}
		},
		setModal() {
			const createModal = (_appendHTML,_target) => {
				const $container = document.createElement('div');
				$container.className = 'mod-modal-overlay';
				$container.innerHTML = _appendHTML;
				document.body.appendChild($container);

				$container.classList.add(a);
				setTimeout(() => {
					$container.classList.add(v);
					document.querySelectorAll('.mod-modal-closeArea, .mod-modal-closeBtn, .mod-modalContent a').forEach((ele) => {
						ele.addEventListener('click', (e) => {
							$container.classList.remove(v);
							setTimeout(() => {
								$container.classList.remove(a);
								$container.remove();
								_target.focus();
							}, 400);
						});
					});
					window.addEventListener("keydown", function (event) {
						if(document.querySelectorAll('.mod-modal-overlay').length) {
							// タブキーが押された時
							if (event.key === "Tab") {
								event.preventDefault();
								// モーダル要素内のフォーカス可能な要素の一覧を取得
								const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
								const focusableElements = Array.from(
								document.querySelector('.mod-modal-overlay').querySelectorAll(focusableElementsSelector)
								);
								// 現在のフォーカス位置を取得
								const focusedItemIndex = focusableElements.indexOf(document.activeElement);
								// shiftキーと同時に押されてた場合
								if (event.shiftKey) {
								if (focusedItemIndex === 0) {
									// 現在のフォーカスが最初の要素の場合、最後の要素にフォーカスを移動
									focusableElements[focusableElements.length - 1].focus();
								} else {
									// 現在のフォーカスが最初の要素以外の場合、前の要素にフォーカスを移動
									focusableElements[focusedItemIndex - 1].focus();
								}
								} else {
								if (focusedItemIndex === focusableElements.length - 1) {
									// 現在のフォーカスが最後の要素の場合、最初の要素にフォーカスを移動
									focusableElements[0].focus();
								} else {
									// 現在のフォーカスが最後の要素以外の場合、次の要素にフォーカスを移動
									focusableElements[focusedItemIndex + 1].focus();
								}
								}
							}
							// ESCキーが押された時
							if (event.key === "Escape") {
								event.preventDefault();
								$container.classList.remove(v);
								setTimeout(() => {
									$container.classList.remove(a);
									$container.remove();
									_target.focus();
								}, 400);
							}
						}
					});
				});
			};
			document.querySelectorAll('.mod-modal').forEach((ele) => {
				ele.addEventListener('click', (e) => {
					let targetKey = e.currentTarget.getAttribute('data-target');
					let $content = document.querySelector(`.mod-modalContent[data-target="${targetKey}"]`);
					if ($content.outerHTML) {
						let appendHTML = `<div class="mod-modal-wrap"><div class="mod-modal-closeArea"></div>
							<button type="button" class="mod-modal-closeBtn" aria-label="close"><svg aria-hidden="true">
							<use xlink:href="/common/svg/sprite.svg#close" /></svg></button>
							${$content.outerHTML}</div>`;
						createModal(appendHTML,e.currentTarget);
					}
					return false;
				});
			});

			// 画像1個だけのモーダル
			document.querySelectorAll('.mod-modalImg').forEach((ele) => {
				ele.addEventListener('click', (e) => {
					let appendHTML = `<div class="mod-modal-wrap"><div class="mod-modal-closeArea"></div>
					<button type="button" class="mod-modal-closeBtn" aria-label="close"><svg aria-hidden="true">
					<use xlink:href="/common/svg/sprite.svg#close" /></svg></button>
						${e.currentTarget.querySelector('img').outerHTML}</div>`;
					createModal(appendHTML,e.currentTarget);
					return false;
				});
			});
		},
		matchHeight() {
			const $elements = document.querySelectorAll( '[data-mh]' );
			const groups = new Map();

			$elements.forEach( ele => { ele.style.height = 'auto'; } ); // 初期化

			$elements.forEach( ele => {
				const attributeName = ele.getAttribute( 'data-mh' );
				if ( !groups.has( attributeName ) ) {
					groups.set( attributeName, [] );
				}
				groups.get( attributeName ).push( ele );
			} );
			groups.forEach( group => {
				const attributeGroups = new Map();
				group.forEach( ele => {
					const attributeName = ele.getAttribute( 'data-mh' );
					const topPosition = ele.getBoundingClientRect().top;
					const key = attributeName + '_' + topPosition;
					if ( !attributeGroups.has( key ) ) {
						attributeGroups.set( key, [] );
					}
					attributeGroups.get( key ).push( ele );
				} );
				attributeGroups.forEach( (item) => {
					const maxHeight = Math.max( ...item.map( ele => ele.offsetHeight ) );
					item.forEach( (ele2) => {
						ele2.style.height = `${maxHeight}px`;
					});
				});
			} );
		},
		inViewAnimation() {
			const target = document.querySelectorAll('.js-fade');
			const options = {
				root: null,
				rootMargin: '-30% 0px',
				threshold: 0
			};
			const callback = (entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						entry.target.classList.add(v);
					}
				});
			};
			const observer = new IntersectionObserver(callback, options);

			target.forEach(ele => {
				observer.observe(ele);
			});
		},
		fileSize() {
			const bytesToSize = (bytes) => {
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
				if (bytes === 0) {
					return 'n/a';
				}
				var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
				if (i === 0) {
					return bytes + ' ' + sizes[i];
				}
				return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
			}
			if(document.querySelectorAll('.js-fileSize').length) {
				document.querySelectorAll('.js-fileSize').forEach(function (pdfElement) {
					pdfElement.innerText = bytesToSize(pdfElement.innerText);
				});
			}
		},
		setSlider() {
			if (document.querySelectorAll('.mod-slider01Wrap').length) {
				document.querySelectorAll('.mod-slider01Wrap').forEach((ele, idx) => {
					let sliderid = 'mod-slider01_' + idx;
					ele.setAttribute('id', sliderid);
					sliderid = '#' + sliderid;
					let swiper = new Swiper(sliderid + " .mod-slider01", {
						slidesPerView: "auto",
						spaceBetween: 22,
						speed: 1000,
						autoplay: true,
						watchSlidesProgress: true,
						pagination: {
							el: sliderid + ' .mod-slider01-control .swiper-pagination',
							clickable: true,
							type:"bullets"
						},
						breakpoints: {
							768: {
								slidesPerView: "auto",
								spaceBetween: 22,
							}
						},
					});
					const button = document.querySelector(sliderid + ' .mod-slider01-control-button');
					if (button) {
					    button.addEventListener('click', (e) => {
					        console.log("hoge", e.currentTarget, swiper);
					        if (!(e.currentTarget.classList.contains(a))) {
					            e.currentTarget.ariaLabel = '停止';
					            e.currentTarget.classList.add(a);
					            swiper.autoplay.start();
					        } else {
					            e.currentTarget.ariaLabel = '再生';
					            e.currentTarget.classList.remove(a);
					            swiper.autoplay.stop();
					        }
					    });
					}
				});
			}
		},
		checkContact() {
			let checkContactElement = document.querySelector('.js-checkContact');
		    if (checkContactElement) {
		        // すべての「cf_ci_contact_info」要素を取得
		        let contactInfoElements = checkContactElement.querySelectorAll('.cf_ci_contact_info');
		        
		        contactInfoElements.forEach(function(contactInfoElement) {
		            // 各「cf_ci_contact_info」要素が空であれば削除
		            if (contactInfoElement.innerHTML.trim() === '') {
		                contactInfoElement.remove();
		            }
		        });
		    }
	    },
		loadDelayScript() {
			let _this = this;
			_this.nextToggle();
			_this.tabSwitch();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.headerMenu();
			_this.headerMegaMenu();
			_this.setImgAttrWidthHeight();
			_this.setModal();
			_this.inViewAnimation();
			_this.matchHeight();
			_this.fileSize();
			_this.setSlider();
			_this.checkContact();
			let resizeTime = 0;
			window.addEventListener('load', function() {
			    _this.matchHeight();
			});
			window.addEventListener('resize', () => {
				clearTimeout(resizeTime);
				resizeTime = setTimeout(() => { _this.matchHeight(); }, 300);
			});
		}
	};

	document.addEventListener('DOMContentLoaded', () => FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript());
})();